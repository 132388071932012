import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentDownloadsDefaultVariants = {}
const cmsContentDownloadsCompoundVariants = []

const cmsContentDownloadsSlotNames = [
  [
    "btn",
    "cms-downloads__btn"
  ],
  [
    "titleText",
    "cms-downloads__titleText"
  ],
  [
    "info",
    "cms-downloads__info"
  ],
  [
    "infoContent",
    "cms-downloads__infoContent"
  ],
  [
    "infoExt",
    "cms-downloads__infoExt"
  ],
  [
    "icon",
    "cms-downloads__icon"
  ]
]
const cmsContentDownloadsSlotFns = /* @__PURE__ */ cmsContentDownloadsSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentDownloadsDefaultVariants, getSlotCompoundVariant(cmsContentDownloadsCompoundVariants, slotName))])

const cmsContentDownloadsFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentDownloadsSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentDownloadsVariantKeys = []

export const cmsContentDownloads = /* @__PURE__ */ Object.assign(cmsContentDownloadsFn, {
  __recipe__: false,
  __name__: 'cmsContentDownloads',
  raw: (props) => props,
  variantKeys: cmsContentDownloadsVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentDownloadsVariantKeys)
  },
})