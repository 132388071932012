import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentCTADefaultVariants = {}
const cmsContentCTACompoundVariants = []

const cmsContentCTASlotNames = [
  [
    "bgImageBox",
    "cms-cta__bgImageBox"
  ],
  [
    "container",
    "cms-cta__container"
  ],
  [
    "overlay",
    "cms-cta__overlay"
  ],
  [
    "overlayInner",
    "cms-cta__overlayInner"
  ],
  [
    "teaser",
    "cms-cta__teaser"
  ],
  [
    "text",
    "cms-cta__text"
  ],
  [
    "links",
    "cms-cta__links"
  ]
]
const cmsContentCTASlotFns = /* @__PURE__ */ cmsContentCTASlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentCTADefaultVariants, getSlotCompoundVariant(cmsContentCTACompoundVariants, slotName))])

const cmsContentCTAFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentCTASlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentCTAVariantKeys = [
  "variant"
]

export const cmsContentCTA = /* @__PURE__ */ Object.assign(cmsContentCTAFn, {
  __recipe__: false,
  __name__: 'cmsContentCTA',
  raw: (props) => props,
  variantKeys: cmsContentCTAVariantKeys,
  variantMap: {
  "variant": [
    "big_bg_image",
    "small",
    "small_shift_right"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentCTAVariantKeys)
  },
})