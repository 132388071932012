import { stringify } from 'qs';

import { ResponseInfo } from '../../../../types';
import { getApiError } from '../../../error';
import { Locale } from '../../../i18n';
import { CMS_AUTH_HEADER } from '../../configs';
import { Cms_ListResponse, Cms_QueryParams } from '../../types';
import { populatePage } from '../pages/pages.populate';

import type { Cms_BlogPost } from './blogPosts.types';

const BASE_PATH = 'blog-posts';

/**
 * GET /api/blog-posts
 */
export const getBlogPosts = async (
  tenantId?: string, // Tenant_Uid,
  locale?: Locale,
  populate?: any
): Promise<Cms_ListResponse<Cms_BlogPost>> => {
  const errorMsg = `Failed to fetch blog posts`;
  try {
    const params: Cms_QueryParams = {
      publicationState: 'live',
      populate: populate || ['thumbnail'],
      locale,
      pagination: {
        page: 1,
        pageSize: 100
      },
      sort: 'publicationDate:desc',
      filters: tenantId
        ? {
            tenants: {
              uid: tenantId
            }
          }
        : undefined
    };

    const query = stringify(params, { encodeValuesOnly: true });

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}?${query}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'blog-posts'] /* revalidate: API_DEFAULT_REVALIDATE */ }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};

/**
 * GET /api/blog-posts
 */
export const getBlogPostBySlug = async (slug: string): Promise<Cms_BlogPost | ResponseInfo> => {
  const errorMsg = `Failed to fetch blog posts by slug: ${slug}`;
  try {
    const params: Cms_QueryParams = {
      publicationState: 'live',
      populate: populatePage as any,
      pagination: {
        page: 1,
        pageSize: 100
      },
      filters: {
        slugInternal: slug
      }
    };

    const query = stringify(params, { encodeValuesOnly: true });

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}?${query}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'blog-posts'] }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data.data[0],
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};
