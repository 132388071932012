import { stringify } from 'qs';

import { ResponseInfo } from '../../../../types';
import { getApiError } from '../../../error';
import { CMS_AUTH_HEADER } from '../../configs';
import { Cms_ListResponse, Cms_QueryParams } from '../../types';

import type { Cms_Icon } from './icons.types';

const BASE_PATH = 'icons';

/**
 * GET /api/icons/icons
 */
export const getIcons = async (): Promise<Cms_ListResponse<Cms_Icon>> => {
  const errorMsg = `Failed to fetch icons`;
  try {
    const queryParams: Cms_QueryParams = {
      pagination: {
        page: 1,
        pageSize: 1000
      }
    };

    const query = stringify(queryParams, { encodeValuesOnly: true });

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}/icons?${query}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'icons'] }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};

/**
 * GET /api/icons/icons/:name
 */
export const getIconByName = async (
  iconName?: string
): Promise<ResponseInfo<Cms_Icon> | ResponseInfo> => {
  const errorMsg = `Failed to fetch icon by name ${iconName}`;
  try {
    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}/icons/${iconName}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'icons'] }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};
