'use client';

import React, { useCallback } from 'react';

import { Container, GridItem } from '@pt-frontends/styled-system/jsx';
import { flyoutMenuSt } from '@pt-frontends/styled-system-web/recipes';
import type { NavigationMenuContentProps } from '@radix-ui/react-navigation-menu';
import * as NavRx from '@radix-ui/react-navigation-menu';

import type { Cms_FlyoutMenu, Cms_SitemapTreeItem } from '@lib/services/cms';

import { CmsEditorText } from '@ui/cmsEditorText';
import { CmsLink, CmsNavLink } from '@ui/cmsLink';
import { LayoutGrid } from '@ui/layout';

import { FlyoutSlider } from './FlyoutSlider';

interface FlyoutMenuProps extends NavigationMenuContentProps {
  data: Cms_SitemapTreeItem;
  flyoutMenu?: Cms_FlyoutMenu;
  activeItemId?: number;
}

const FlyoutMenu = React.forwardRef<HTMLDivElement, FlyoutMenuProps>((props, ref) => {
  const { navContent, navContentInner, teaserBox, infoTitle, infoText, menuContainer, menuList } =
    flyoutMenuSt();
  const { data, flyoutMenu, activeItemId, ...restProps } = props;
  const subNavGroups = data.children?.filter(
    item => /* item.children?.length > 0 && */ item.active
  );

  const getMenuItems = useCallback((item: Cms_SitemapTreeItem) => {
    const items = item.children?.filter(c => c.active);

    return items;
  }, []);

  return (
    <NavRx.Content className={navContent}>
      <div className={navContentInner}>
        <Container>
          <LayoutGrid gap={8} my="62px">
            {/* INFO BOX */}
            <GridItem colSpan={3} display="flex" flexDir="column" pos="relative">
              <h2 className={infoTitle}>{flyoutMenu?.displayTitle || data.title}</h2>
              <CmsEditorText data={flyoutMenu?.text} variant="blockSm" className={infoText} />
              {flyoutMenu?.link && (
                <CmsLink
                  link={flyoutMenu?.link}
                  button={{ icon: 'arrow-right', variant: flyoutMenu.link.buttonType || 'primary' }}
                />
              )}
            </GridItem>

            {/* MENU BOX */}
            <GridItem colSpan={6} pos="relative">
              <LayoutGrid gap={8} pos="relative">
                {subNavGroups?.map(item => (
                  <GridItem key={item.id} colSpan={6} className={menuContainer}>
                    <h3>
                      {/* @ts-ignore TODO FIX THIS */}
                      <NavRx.Link asChild ref={ref} {...restProps}>
                        <CmsNavLink
                          navItem={item}
                          className={
                            flyoutMenuSt({ active: activeItemId === item.id }).navRubricLink
                          }
                        />
                      </NavRx.Link>
                    </h3>
                    <ul className={menuList}>
                      {getMenuItems(item).map(child => (
                        <li key={child.id}>
                          {/* @ts-ignore TODO FIX THIS */}
                          <NavRx.Link asChild ref={ref} {...restProps}>
                            <CmsNavLink
                              navItem={child}
                              className={
                                flyoutMenuSt({ active: activeItemId === child.id }).navLink
                              }
                            />
                          </NavRx.Link>
                        </li>
                      ))}
                    </ul>
                  </GridItem>
                ))}
              </LayoutGrid>
            </GridItem>

            {/* TEASER BOX */}
            <GridItem colSpan={3} className={teaserBox}>
              {flyoutMenu && <FlyoutSlider data={flyoutMenu} />}
            </GridItem>
          </LayoutGrid>
        </Container>
      </div>
    </NavRx.Content>
  );
});
FlyoutMenu.displayName = 'FlyoutMenu';

export { FlyoutMenu };
export type { FlyoutMenuProps };
