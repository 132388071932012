import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentHeaderDefaultVariants = {}
const cmsContentHeaderCompoundVariants = []

const cmsContentHeaderSlotNames = [
  [
    "image",
    "cms-header__image"
  ],
  [
    "teaserText",
    "cms-header__teaserText"
  ],
  [
    "titleText",
    "cms-header__titleText"
  ],
  [
    "tagsBox",
    "cms-header__tagsBox"
  ],
  [
    "tag",
    "cms-header__tag"
  ],
  [
    "text",
    "cms-header__text"
  ]
]
const cmsContentHeaderSlotFns = /* @__PURE__ */ cmsContentHeaderSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentHeaderDefaultVariants, getSlotCompoundVariant(cmsContentHeaderCompoundVariants, slotName))])

const cmsContentHeaderFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentHeaderSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentHeaderVariantKeys = [
  "variant"
]

export const cmsContentHeader = /* @__PURE__ */ Object.assign(cmsContentHeaderFn, {
  __recipe__: false,
  __name__: 'cmsContentHeader',
  raw: (props) => props,
  variantKeys: cmsContentHeaderVariantKeys,
  variantMap: {
  "variant": [
    "big",
    "colored",
    "split_50_50",
    "plain"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentHeaderVariantKeys)
  },
})