'use client';

import React from 'react';

import { useTranslation } from '@lib/services/i18n/client';

import { Button } from '@ui/button';

const AuthLoginAction: React.FC = () => {
  const { t } = useTranslation();

  const handleLogin = () => {
    // eslint-disable-next-line no-alert
    alert('Log In!');
  };

  return (
    <Button size="small" variant="secondary" icon="arrow-right" onClick={handleLogin}>
      {t('uiWeb.auth.logIn')}
    </Button>
  );
};

export { AuthLoginAction };
