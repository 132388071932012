'use client';

import React, { useCallback, useEffect, useState } from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { icon } from '@pt-frontends/styled-system/recipes';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { getIconByNameServer } from '@lib/server-actions';
import { Cms_Icon } from '@lib/services/cms';

import type { CmsIconProps } from './CmsIcon.types';
import CmsIconSvgNode from './CmsIconSvgNode';

const devToolsName = 'CMS_ICON_STORE';

interface CmsIconStore {
  icons: {
    [key: string]: Cms_Icon;
  };
  setIcon: (i: Cms_Icon) => void;
}

const useCmsIconStore = create<
  CmsIconStore,
  [['zustand/devtools', never], ['zustand/immer', never]]
>(
  devtools(
    immer(set => ({
      icons: {},

      setIcon: i => {
        set(draft => {
          draft.icons[i.name] = i;
        });
      }
    })),
    { name: devToolsName }
  )
);

export const CmsIconClient: React.FC<CmsIconProps> = props => {
  const { i, className, size } = props;
  const [isError, setError] = useState(false);
  const data = useCmsIconStore(state => state.icons[i || 'undefined']);
  const setData = useCmsIconStore(state => state.setIcon);

  const loadIcon = useCallback(async () => {
    if (!i || isError) return null;

    const iconRes = await getIconByNameServer(i);

    if (iconRes?._status !== 200 || !iconRes.data?.svgJson) {
      setError(true);

      return null;
    }

    if (iconRes.data.svgJson) {
      setError(false);
      setData(iconRes.data);
    }
  }, [i, isError, setData]);

  useEffect(() => {
    if (!i || isError || data) return;

    loadIcon();
  }, [i, isError, data, loadIcon]);

  if (!i || !data)
    return (
      <span
        className={cx('cms-icon', 'cms-icon-empty', `cms-icon-${i}`, icon({ size }), className)}
      />
    );

  return (
    <CmsIconSvgNode
      data={data.svgJson}
      data-icon={i}
      className={cx('cms-icon', `cms-icon-${i}`, icon({ size }), className)}
    />
  );
};
