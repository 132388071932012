import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentHeaderPlainDefaultVariants = {}
const cmsContentHeaderPlainCompoundVariants = []

const cmsContentHeaderPlainSlotNames = [
  [
    "container",
    "cms-header-plain__container"
  ]
]
const cmsContentHeaderPlainSlotFns = /* @__PURE__ */ cmsContentHeaderPlainSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentHeaderPlainDefaultVariants, getSlotCompoundVariant(cmsContentHeaderPlainCompoundVariants, slotName))])

const cmsContentHeaderPlainFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentHeaderPlainSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentHeaderPlainVariantKeys = [
  "all"
]

export const cmsContentHeaderPlain = /* @__PURE__ */ Object.assign(cmsContentHeaderPlainFn, {
  __recipe__: false,
  __name__: 'cmsContentHeaderPlain',
  raw: (props) => props,
  variantKeys: cmsContentHeaderPlainVariantKeys,
  variantMap: {
  "all": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentHeaderPlainVariantKeys)
  },
})