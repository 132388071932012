'use client';

import React from 'react';

import { mobileNavigation } from '@pt-frontends/styled-system-web/recipes';

import type { Cms_FlyoutMenu, Cms_SitemapTreeItem } from '@lib/services/cms';

import { CmsNavLink } from '@ui/cmsLink';

import { FlyoutSlider } from '../flyoutMenu';

type MobileSubItemsProps = {
  data: Cms_SitemapTreeItem | Cms_SitemapTreeItem[] | null;
  depth?: number;
  activeItemId?: number | undefined;
  flyoutMenus?: Cms_FlyoutMenu[];
  itemId?: number;
};

const MobileSubItems: React.FC<MobileSubItemsProps> = ({
  data,
  depth = 0,
  activeItemId,
  flyoutMenus,
  itemId
}) => {
  const isTopLevel = depth === 1;
  const { menuList, menuListItem, teaserBox } = mobileNavigation({ isTopLevel });

  if (!data) return null;

  const flyoutMenu = flyoutMenus?.find(flm => flm.sitemapNode?.nodeId === itemId);

  if (Array.isArray(data)) {
    return (
      <>
        {data.filter(item => item.include?.includes('header')).length > 0 && (
          <ul className={menuList}>
            {data
              .filter(item => item.include?.includes('header'))
              .map(item => (
                <li className={menuListItem} key={item.id}>
                  {/* {linkContent(item)} */}
                  <CmsNavLink
                    navItem={item}
                    className={
                      mobileNavigation({ active: activeItemId === item.id, isTopLevel }).navLink
                    }
                  />

                  {item.children?.filter(c => c.active).length > 0 && (
                    <MobileSubItems
                      data={item.children?.filter(c => c.active)}
                      depth={depth + 1}
                      activeItemId={activeItemId}
                      itemId={itemId}
                      flyoutMenus={flyoutMenus}
                    />
                  )}
                </li>
              ))}
          </ul>
        )}
        {depth === 1 && flyoutMenu && (
          <div className={teaserBox}>
            <FlyoutSlider data={flyoutMenu} isMobile />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {data.children?.filter(c => c.active).length > 0 && (
        <MobileSubItems
          data={data.children?.filter(c => c.active)}
          depth={depth + 1}
          activeItemId={activeItemId}
          itemId={itemId}
          flyoutMenus={flyoutMenus}
        />
      )}
    </div>
  );
};

export { MobileSubItems };
export type { MobileSubItemsProps };
