/**
 * https://ui.shadcn.com/docs/components/label
 * radix: https://www.radix-ui.com/primitives/docs/components/label
 */

'use client';

import * as React from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { LabelVariantProps, label } from '@pt-frontends/styled-system/recipes';
import * as LabelPrimitive from '@radix-ui/react-label';

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    LabelVariantProps & { required?: boolean; disabled?: boolean; requiredCl?: string }
>(({ className, children, required, disabled, requiredCl, ...rest }, ref) => {
  const { root, requiredStar } = label({ disabled });

  return (
    <LabelPrimitive.Root ref={ref} className={cx(root, className)} {...rest}>
      {children}
      {required && <span className={cx(requiredStar, requiredCl)}>*</span>}
    </LabelPrimitive.Root>
  );
});
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
