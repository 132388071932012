import { getApiError } from '../../../error';
import { CMS_AUTH_HEADER } from '../../configs';
import { Cms_ContactFormData } from '../../types/contactForm';

const BASE_PATH = 'contact-requests';

/**
 * POST /api/contact-requests
 */
export const postContactForm = async (
  cfData: Cms_ContactFormData,
  url: string,
  tenantId: number,
  context?: string | null,
  referer?: string | null
): Promise<any> => {
  const errorMsg = `Failed to post contact form`;
  try {
    const params = { data: { ...cfData, tenant: tenantId, context, url, referer } };

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}`, {
      headers: {
        'Content-Type': 'application/json',
        ...CMS_AUTH_HEADER
      },
      method: 'POST',
      body: JSON.stringify(params)
    });

    if (res.status !== 200 && res.status !== 204) {
      return getApiError(`CONTACT FORM ERROR: Failed to send Contact Form`, res, null);
    }

    const data = await res?.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};
