'use client';

import React from 'react';

import { css, cva } from '@pt-frontends/styled-system/css';
import { AspectRatio, Flex } from '@pt-frontends/styled-system/jsx';
import { default as AutoPl } from 'embla-carousel-autoplay';

import type { Cms_FlyoutMenu } from '@lib/services/cms';

import { Carousel, CarouselContent, CarouselItem, CarouselPagination } from '@ui/carousel';
import { CmsEditorText } from '@ui/cmsEditorText';
import { CmsLink } from '@ui/cmsLink';
import { Img } from '@ui/img';

const text = cva({
  base: {
    opacity: 0,
    transition: 'opacity 0.4s ease-in-out',

    '& *': {
      fontWeight: '400!',
      fontSize: '18px',
      lineHeight: '26px'
    }
  },
  variants: {
    isActive: {
      true: {
        opacity: 1
      }
    }
  }
});

interface SliderItemProps {
  data: any;
  isActive?: boolean;
}
const FlyoutSliderItem: React.FC<SliderItemProps> = ({ data, isActive }) => {
  const inner = (
    <Flex flexDir="column" gap="22px">
      <AspectRatio bg="grey.bg" ratio={1.67} rounded={4} overflow="hidden">
        <Img fill data={data.image} />
      </AspectRatio>
      <CmsEditorText
        data={data.text}
        isNestedLinks
        variant="blockSm"
        className={text({ isActive })}
      />
    </Flex>
  );

  if (data.link) {
    return <CmsLink link={data.link}>{inner}</CmsLink>;
  }

  return inner;
};

interface Props {
  data: Cms_FlyoutMenu;
  isMobile?: boolean;
}
export const FlyoutSlider: React.FC<Props> = ({ data, isMobile }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  if (!data.slides || data.slides.length === 0) return null;

  return (
    <div
      className={css({
        pb: 6
      })}
    >
      <Carousel
        onIndexChange={i => setCurrentIndex(i)}
        opts={{ loop: true }}
        plugins={[AutoPl({ delay: 3000 })]}
        animation={!isMobile ? 'fade' : 'slide'}
      >
        <CarouselContent className={css({ pb: 6 })}>
          {data?.slides.map((slide, index) => (
            <CarouselItem key={slide.id} index={index}>
              <FlyoutSliderItem data={slide} isActive={currentIndex === index} />
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselPagination
          className={css({
            bottom: -6,
            left: 0,
            right: 0
          })}
        />
      </Carousel>
    </div>
  );
};
