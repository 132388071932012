import { stringify } from 'qs';

import { getApiError } from '../../../error';
import { Locale } from '../../../i18n';
import { CMS_AUTH_HEADER } from '../../configs';
import { Cms_ListResponse, Cms_QueryParams } from '../../types';

import type { Cms_Tenant } from './tenants.types';

const BASE_PATH = 'tenants';

const TENANT_POPULATE = {
  logo: true,
  footerLogo: true,
  favicon: true,
  siteMeta: {
    populate: {
      schemas: true
    }
  },
  contactFooter: true,
  socials: {
    populate: {
      icon: true
    }
  },
  footerLinks: true,
  technicalConfig: {
    populate: {
      analytics: true,
      contactFormRecaptcha: true,
      contactFormSmtp: true,
      keycloak: true,
      fullTextSearch: true
    }
  },
  headerBanner: {
    populate: {
      bannerItems: true
    }
  },
  teamFallbackImage: true,
  sitemap: true
};

/**
 * GET /api/ibes
 */
export const getTenants = async (
  params?: Cms_QueryParams
): Promise<Cms_ListResponse<Cms_Tenant>> => {
  const errorMsg = `Failed to fetch tenants`;
  try {
    const queryParams: Cms_QueryParams = {
      ...params,
      populate: params?.populate || TENANT_POPULATE
    };

    const query = stringify(queryParams, { encodeValuesOnly: true });

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}?${query}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'tennants'] }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};

/**
 * GET /api/ibes/{id}
 */
export const getTenantById = async (id: number): Promise<any> => {
  const errorMsg = `Failed to fetch tenant by id ${id}`;
  try {
    const queryParams: Cms_QueryParams = {
      populate: /* params?.populate ||  */ TENANT_POPULATE
    };

    const query = stringify(queryParams, { encodeValuesOnly: true });

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}/${id}?${query}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'tennantById'] /*  revalidate: API_DEFAULT_REVALIDATE */ }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};

/**
 * GET /api/ibes/
 */
export const getTenantByUid = async (_locale: Locale, uid: string): Promise<any> => {
  const errorMsg = `Failed to fetch tenant by uid ${uid}`;
  try {
    const queryParams: Cms_QueryParams = {
      populate: TENANT_POPULATE,
      filters: {
        uid: uid
      }
    };

    const query = stringify(queryParams, { encodeValuesOnly: true });

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}?${query}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'tenantByUid'] /* revalidate: API_DEFAULT_REVALIDATE */ }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};
