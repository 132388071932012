/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-use-before-define */
/* https://ui.shadcn.com/docs/components/form */

import * as React from 'react';

import {
  TextField as MuiTextField,
  FormControl as MuiFormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  InputLabel
} from '@mui/material';
import { css, cx } from '@pt-frontends/styled-system/css';
import { form, label } from '@pt-frontends/styled-system/recipes';
import type * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import { Eye, EyeOff, X } from 'lucide-react';
import type {
  ControllerProps,
  ControllerRenderProps,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';

import { Input, InputPassword, InputProps, InputWrapper } from '@ui/input';
import { Label } from '@ui/label';

const Form = FormProvider;

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => (
  <FormFieldContext.Provider value={{ name: props.name }}>
    <Controller {...props} />
  </FormFieldContext.Provider>
);

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const id = React.useId();
    const { item } = form();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={cx(item, className)} {...props} />
      </FormItemContext.Provider>
    );
  }
);
FormItem.displayName = 'FormItem';

const labelInt = css({
  mb: 1.5,
  display: 'inline-block'
});

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & { required?: boolean }
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();
  const { root } = label({ error: error !== undefined });

  return (
    <Label
      ref={ref}
      className={cx(error && 'kc-form-label-error', root, labelInt, className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId, error } = useFormField();
  const { description } = form();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cx(error && 'form-description-error', description, className)}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;
  const { message } = form({ error: error !== undefined });

  if (!body) {
    return null;
  }

  return (
    <p ref={ref} id={formMessageId} className={cx(message, className)} {...props}>
      {body}
    </p>
  );
});
FormMessage.displayName = 'FormMessage';

const FormFieldResetBtn = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
    value?: any;
    form?: any;
    name?: string;
  }
>(({ className, disabled, value, children, form, onChange, name, ...props }, ref) => {
  const { error } = useFormField();
  const { clearBtn, clearBtnIcon } = form({ disabled, error: error !== undefined });
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onChange && !form) {
      onChange(e);

      return;
    }
    if (name) {
      form.setValue(name, '');
    }
  };

  if (!value) {
    return null;
  }

  return (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      className={cx(clearBtn, className)}
      {...props}
      onClick={handleClick}
    >
      {children || <X className={clearBtnIcon} />}
    </button>
  );
});

FormFieldResetBtn.displayName = 'FormFieldResetBtn';

export interface FormInputTextProps extends InputProps {
  label: string | React.ReactNode;
  isMui?: boolean;
  required?: boolean;
  field: ControllerRenderProps<any>;
  multiLine?: boolean;
  rows?: number;
}

const FormInputText = React.forwardRef<HTMLDivElement, FormInputTextProps>((props, ref) => {
  const { error } = useFormField();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { color, size, isMui, required, label, field, tabIndex, multiLine, rows, ...rest } = props;

  if (isMui) {
    return (
      <FormItem ref={ref}>
        <FormControl>
          <MuiTextField
            //  ref={ref}
            {...rest}
            {...field}
            required={required}
            error={error !== undefined}
            helperText={error?.message as any}
            label={label}
            multiline={multiLine}
            // disable native html5 field validation
            inputProps={{ ...rest, required: false, tabIndex }}
          />
        </FormControl>
      </FormItem>
    );
  }

  return (
    <FormItem ref={ref}>
      <FormLabel required>{label}</FormLabel>
      <FormControl>
        <InputWrapper>
          <Input {...rest} {...field} tabIndex={tabIndex} />
          <FormFieldResetBtn {...field} tabIndex={-1} />
        </InputWrapper>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
});
FormInputText.displayName = 'FormInputText';

export interface InputPasswordProps extends InputProps {
  label?: string | React.ReactNode;
  isMui?: boolean;
  required?: boolean;
  field: ControllerRenderProps;
}

const FormInputPassword = React.forwardRef<HTMLDivElement, InputPasswordProps>((props, ref) => {
  const { error } = useFormField();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { color, size, isMui, required, label, field, tabIndex, ...rest } = props;
  const [isVisible, setVisible] = React.useState(false);

  if (isMui) {
    return (
      <FormItem ref={ref}>
        <FormControl>
          <MuiFormControl variant="outlined" error={error !== undefined}>
            <InputLabel required={required} error={error !== undefined} htmlFor={props.id}>
              {label}
            </InputLabel>
            <OutlinedInput
              {...rest}
              {...field}
              required={required}
              error={error !== undefined}
              label={label}
              type={!isVisible ? 'password' : 'text'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setVisible(!isVisible)}
                    edge="end"
                  >
                    {isVisible ? <EyeOff strokeWidth={1.5} /> : <Eye strokeWidth={1.5} />}
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby={`${props.id}-helper-text`}
              inputProps={{ ...rest, required: false, tabIndex }}
            />
            <FormHelperText id={`${props.id}-helper-text`} error={error !== undefined}>
              {error?.message as any}
            </FormHelperText>
          </MuiFormControl>
        </FormControl>
      </FormItem>
    );
  }

  return (
    <FormItem ref={ref}>
      <FormLabel required>{label}</FormLabel>
      <FormControl>
        <InputWrapper>
          <InputPassword {...rest} {...field} tabIndex={tabIndex} />
          <FormFieldResetBtn {...field} tabIndex={-1} />
        </InputWrapper>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
});
FormInputPassword.displayName = 'FormInputPassword';

export {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormFieldResetBtn,
  FormItem,
  FormLabel,
  FormMessage,
  useFormField,

  // input wrappers
  FormInputText,
  FormInputPassword
};
