import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentUspsDefaultVariants = {}
const cmsContentUspsCompoundVariants = []

const cmsContentUspsSlotNames = [
  [
    "box",
    "cms-usps__box"
  ],
  [
    "iconBox",
    "cms-usps__iconBox"
  ],
  [
    "icon",
    "cms-usps__icon"
  ],
  [
    "contentBox",
    "cms-usps__contentBox"
  ],
  [
    "title",
    "cms-usps__title"
  ],
  [
    "links",
    "cms-usps__links"
  ]
]
const cmsContentUspsSlotFns = /* @__PURE__ */ cmsContentUspsSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentUspsDefaultVariants, getSlotCompoundVariant(cmsContentUspsCompoundVariants, slotName))])

const cmsContentUspsFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentUspsSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentUspsVariantKeys = [
  "variant"
]

export const cmsContentUsps = /* @__PURE__ */ Object.assign(cmsContentUspsFn, {
  __recipe__: false,
  __name__: 'cmsContentUsps',
  raw: (props) => props,
  variantKeys: cmsContentUspsVariantKeys,
  variantMap: {
  "variant": [
    "cols_2",
    "cols_3"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentUspsVariantKeys)
  },
})