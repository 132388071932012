import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentParallaxListDefaultVariants = {}
const cmsContentParallaxListCompoundVariants = []

const cmsContentParallaxListSlotNames = [
  [
    "parallaxBox",
    "cms-parallax-list__parallaxBox"
  ],
  [
    "parallaxBoxMobile",
    "cms-parallax-list__parallaxBoxMobile"
  ],
  [
    "contentBox",
    "cms-parallax-list__contentBox"
  ],
  [
    "boxInner",
    "cms-parallax-list__boxInner"
  ],
  [
    "item",
    "cms-parallax-list__item"
  ],
  [
    "teaser",
    "cms-parallax-list__teaser"
  ],
  [
    "title",
    "cms-parallax-list__title"
  ],
  [
    "text",
    "cms-parallax-list__text"
  ]
]
const cmsContentParallaxListSlotFns = /* @__PURE__ */ cmsContentParallaxListSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentParallaxListDefaultVariants, getSlotCompoundVariant(cmsContentParallaxListCompoundVariants, slotName))])

const cmsContentParallaxListFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentParallaxListSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentParallaxListVariantKeys = []

export const cmsContentParallaxList = /* @__PURE__ */ Object.assign(cmsContentParallaxListFn, {
  __recipe__: false,
  __name__: 'cmsContentParallaxList',
  raw: (props) => props,
  variantKeys: cmsContentParallaxListVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentParallaxListVariantKeys)
  },
})