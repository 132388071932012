import {
  getPages,
  getPageById,
  getSitemapTrees,
  getSitemapTreesByGroup,
  getSitemaps,
  getBlogPosts,
  getBlogPostBySlug,
  getIcons,
  getIconByName,
  getTeamMembers
} from './api';
import { getFlyoutMenus } from './api/flyoutMenus';
import { getPartners } from './api/partners';
import { abortSearch, searchContents } from './api/search';
import { getTenantById, getTenants } from './api/tenants';

export const cmsService = {
  pages: {
    getPageById,
    getPages
  },
  tenants: {
    getTenants,
    getTenantById
  },
  sitemaps: {
    getSitemaps,
    getSitemapTrees,
    getSitemapTreesByGroup
  },
  flyoutMenus: {
    getFlyoutMenus
  },
  partners: {
    getPartners
  },
  blogPosts: {
    getBlogPosts,
    getBlogPostBySlug
  },
  icons: {
    getIcons,
    getIconByName
  },
  teamMembers: {
    getTeamMembers
  },
  search: {
    abortSearch,
    searchContents
  }
};
