import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cookieBannerDefaultVariants = {}
const cookieBannerCompoundVariants = []

const cookieBannerSlotNames = [
  [
    "dialogContent",
    "cookie-banner__dialogContent"
  ],
  [
    "dialogBody",
    "cookie-banner__dialogBody"
  ],
  [
    "dialogBodySrollArea",
    "cookie-banner__dialogBodySrollArea"
  ],
  [
    "line",
    "cookie-banner__line"
  ],
  [
    "cookieInfo",
    "cookie-banner__cookieInfo"
  ],
  [
    "infoText",
    "cookie-banner__infoText"
  ],
  [
    "checkTitle",
    "cookie-banner__checkTitle"
  ],
  [
    "checkText",
    "cookie-banner__checkText"
  ],
  [
    "cookieIcon",
    "cookie-banner__cookieIcon"
  ],
  [
    "footer",
    "cookie-banner__footer"
  ],
  [
    "dialogFooter",
    "cookie-banner__dialogFooter"
  ],
  [
    "footerCookieBtn",
    "cookie-banner__footerCookieBtn"
  ],
  [
    "cookieInfoDate",
    "cookie-banner__cookieInfoDate"
  ]
]
const cookieBannerSlotFns = /* @__PURE__ */ cookieBannerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cookieBannerDefaultVariants, getSlotCompoundVariant(cookieBannerCompoundVariants, slotName))])

const cookieBannerFn = memo((props = {}) => {
  return Object.fromEntries(cookieBannerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cookieBannerVariantKeys = [
  "isManagedCookies"
]

export const cookieBanner = /* @__PURE__ */ Object.assign(cookieBannerFn, {
  __recipe__: false,
  __name__: 'cookieBanner',
  raw: (props) => props,
  variantKeys: cookieBannerVariantKeys,
  variantMap: {
  "isManagedCookies": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cookieBannerVariantKeys)
  },
})