'use client';

import React, { useEffect, useMemo, useState } from 'react';

import { HStack } from '@pt-frontends/styled-system/jsx';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { useCmsParams } from '@lib/hooks';
import type { Cms_BlogPost } from '@lib/services/cms';
import { type CmsRouteParamsBlogPosts, routesService } from '@lib/services/routes';

import { FilterList } from '@ui/filterList';
import { LayoutGrid, LayoutGridTile } from '@ui/layout';

import BlogPostItem from './BlogPostItem';

interface Props {
  blockId: number;
  data: Cms_BlogPost[];
  tags: string[];
  showFilters: boolean;
  filteredValues?: string[];
}

const CmsContentBlogPostsInner: React.FC<Props> = ({
  blockId,
  data,
  tags,
  showFilters,
  filteredValues
}) => {
  const { push } = useRouter();
  const pathname = usePathname();
  const { q } = useCmsParams(blockId);
  const searchParams = useSearchParams();
  const [fValues, setFvalues] = useState<string[]>(filteredValues as string[]);

  const filteredData = useMemo(() => {
    if (!filteredValues?.length) return data;

    const fltData: Cms_BlogPost[] = [];
    data.forEach(p => {
      let isIn = false;
      p.tags?.forEach(t => {
        if (filteredValues?.includes(t)) {
          isIn = true;
        }
      });

      if (isIn) {
        fltData.push(p);
      }
    });

    return fltData;
  }, [data, filteredValues]);

  const handleFiltersChange = (filters: string[]) => {
    const params: CmsRouteParamsBlogPosts | undefined = filters.length
      ? { values: filters }
      : undefined;
    const newQ = routesService.getCmsQueryString(blockId, params, q);

    if (newQ) {
      push(`${pathname}?cms=${newQ}`, { scroll: false });
    } else {
      push(pathname, { scroll: false });
    }
  };

  useEffect(() => {
    setFvalues(filteredValues as string[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('cms'), filteredValues]);

  return (
    <>
      {showFilters && (
        <HStack justifyContent="center">
          <FilterList
            value={fValues || []}
            onValueChange={handleFiltersChange}
            filters={tags
              .sort((a, b) => a.localeCompare(b))
              .map(tag => ({ value: tag, label: tag }))}
          />
        </HStack>
      )}
      <LayoutGrid>
        {filteredData?.map(post => (
          <LayoutGridTile key={post.id}>
            <BlogPostItem key={post.id} data={post} />
          </LayoutGridTile>
        ))}
      </LayoutGrid>
    </>
  );
};

export default CmsContentBlogPostsInner;
