import React from 'react';

import { css, cx } from '@pt-frontends/styled-system/css';
import Link from 'next/link';

import { CmsIconClient } from '@ui/cmsIcon';
import { Icon } from '@ui/icon';

import type { LinkBoxProps } from './LinkBox.types';

const box = css({
  p: 4,
  border: '1px solid {colors.primary.light}',
  rounded: 4,
  display: 'inline-flex',
  flexDir: 'column',
  justifyContent: 'space-between',
  minW: '144px',
  minH: '107px',
  transition: 'colors',
  _hover: {
    bg: 'primary.light'
  }
});

const boxIcon = css({
  w: 6,
  h: 6,
  color: 'primary',
  transition: 'colors',
  _groupHover: {
    color: 'primary'
  }
});

const label = css({
  fontSize: '16px',
  lineHeight: '22px',
  display: 'flex',
  gap: 3,
  whiteSpace: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'colors',
  _groupHover: {
    color: 'primary'
  }
});

const linkIcon = css({
  transition: 'all',
  _groupHover: {
    color: 'primary.dark',
    transform: 'translateX(2px)'
  }
});

const LinkBoxComponent: React.ForwardRefRenderFunction<HTMLAnchorElement, LinkBoxProps> = (
  props,
  ref
) => {
  const {
    icon = 'check-circle',
    children,
    className,
    href,
    target = '_self',
    linkType = 'internal',
    ...restProps
  } = props;

  const content = (
    <>
      <CmsIconClient i={icon} className={boxIcon} />
      <div className={label}>
        {children}
        <Icon className={linkIcon} i="arrow-right" />
      </div>
    </>
  );

  const boxClasses = cx(
    box,
    `link-box-${linkType}`,
    `link-box-${target.replace('_', '')}`,
    'group',
    className
  );

  if (linkType === 'external') {
    <a href={href as string} ref={ref} className={boxClasses} target={target}>
      {content}
    </a>;
  }

  return (
    <Link href={href} ref={ref} className={boxClasses} target={target} {...restProps}>
      {content}
    </Link>
  );
};

export const LinkBox = React.forwardRef(LinkBoxComponent);
