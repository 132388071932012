import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const headerBannerDefaultVariants = {}
const headerBannerCompoundVariants = []

const headerBannerSlotNames = [
  [
    "banner",
    "header-banner__banner"
  ],
  [
    "bannerItem",
    "header-banner__bannerItem"
  ]
]
const headerBannerSlotFns = /* @__PURE__ */ headerBannerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, headerBannerDefaultVariants, getSlotCompoundVariant(headerBannerCompoundVariants, slotName))])

const headerBannerFn = memo((props = {}) => {
  return Object.fromEntries(headerBannerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const headerBannerVariantKeys = [
  "static"
]

export const headerBanner = /* @__PURE__ */ Object.assign(headerBannerFn, {
  __recipe__: false,
  __name__: 'headerBanner',
  raw: (props) => props,
  variantKeys: headerBannerVariantKeys,
  variantMap: {
  "static": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, headerBannerVariantKeys)
  },
})