import { useMemo } from 'react';

import { useMediaQuery } from 'react-responsive';

import { BREAK_POINTS } from '@lib/constants';

export const useBreakpoints = () => {
  const is6Xl = useMediaQuery({ query: `(min-width: ${BREAK_POINTS['6xl']}px)` });
  const is5Xl = useMediaQuery({ query: `(min-width: ${BREAK_POINTS['5xl']}px)` });
  const is4Xl = useMediaQuery({ query: `(min-width: ${BREAK_POINTS['4xl']}px)` });
  const is3Xl = useMediaQuery({ query: `(min-width: ${BREAK_POINTS['3xl']}px)` });
  const is2Xl = useMediaQuery({ query: `(min-width: ${BREAK_POINTS['2xl']}px)` });
  const isXl = useMediaQuery({ query: `(min-width: ${BREAK_POINTS.xl}px)` });
  const isLg = useMediaQuery({ query: `(min-width: ${BREAK_POINTS.lg}px)` });
  const isMd = useMediaQuery({ query: `(min-width: ${BREAK_POINTS.md}px)` });
  const isSm = useMediaQuery({ query: `(min-width: ${BREAK_POINTS.sm}px)` });

  const br = useMemo(
    () => ({
      '6xl': is6Xl,
      '5xl': is5Xl,
      '4xl': is4Xl,
      '3xl': is3Xl,
      '2xl': is2Xl,
      xl: isXl,
      lg: isLg,
      md: isMd,
      sm: isSm
    }),
    [is2Xl, is3Xl, is4Xl, is5Xl, is6Xl, isLg, isMd, isSm, isXl]
  );

  return br;
};
