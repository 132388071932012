import { isServer } from '@lib/utils';

const getDataLayer = () => {
  if (isServer()) return;

  if (!window._env_?.gaId) return;

  return window.dataLayer;
};

export const gaService = {
  getDataLayer
};
