'use client';

import React from 'react';

import { center } from '@pt-frontends/styled-system/patterns';
import { mainNavigation } from '@pt-frontends/styled-system-web/recipes';
import * as NavRx from '@radix-ui/react-navigation-menu';

import { useHeaderBanner } from '@lib/hooks';
import type { Cms_FlyoutMenu, Cms_SitemapTree, Cms_SitemapTreeItem } from '@lib/services/cms';

import { NavItem } from './NavItem';

interface MainNavigationProps {
  pageTree?: Cms_SitemapTree;
  pageTreeItem?: Cms_SitemapTreeItem;
  flyoutMenus?: Cms_FlyoutMenu[];
  hasHeaderBanner?: boolean;
}
const MainNavigation: React.FC<MainNavigationProps> = ({
  pageTree,
  pageTreeItem,
  flyoutMenus,
  hasHeaderBanner
}) => {
  const rootItem = pageTree?.children?.find(item => item.pageId === 'home');
  const items = rootItem?.children?.filter(item => item.active && item.include.includes('header'));
  const isBannerActive = useHeaderBanner(hasHeaderBanner);
  const { viewPort, viewportPos } = mainNavigation({ isBannerActive });

  return (
    <NavRx.Root>
      <NavRx.List className={center({ listStyleType: 'none', m: 0 })}>
        {items?.map(item => (
          <NavItem
            data={item}
            key={item.id}
            flyoutMenu={flyoutMenus?.find(flm => flm.sitemapNode?.nodeId === item.id)}
            activeItemId={pageTreeItem?.id}
          />
        ))}
      </NavRx.List>
      <div className={viewportPos}>
        <NavRx.Viewport className={viewPort} />
      </div>
    </NavRx.Root>
  );
};

export { MainNavigation };
export type { MainNavigationProps };
