import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentTeaserBigDefaultVariants = {}
const cmsContentTeaserBigCompoundVariants = []

const cmsContentTeaserBigSlotNames = [
  [
    "container",
    "cms-teaser-big__container"
  ],
  [
    "inner",
    "cms-teaser-big__inner"
  ],
  [
    "title",
    "cms-teaser-big__title"
  ],
  [
    "teaser",
    "cms-teaser-big__teaser"
  ],
  [
    "links",
    "cms-teaser-big__links"
  ]
]
const cmsContentTeaserBigSlotFns = /* @__PURE__ */ cmsContentTeaserBigSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentTeaserBigDefaultVariants, getSlotCompoundVariant(cmsContentTeaserBigCompoundVariants, slotName))])

const cmsContentTeaserBigFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentTeaserBigSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentTeaserBigVariantKeys = []

export const cmsContentTeaserBig = /* @__PURE__ */ Object.assign(cmsContentTeaserBigFn, {
  __recipe__: false,
  __name__: 'cmsContentTeaserBig',
  raw: (props) => props,
  variantKeys: cmsContentTeaserBigVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentTeaserBigVariantKeys)
  },
})