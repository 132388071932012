'use client';

import React from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { AspectRatio } from '@pt-frontends/styled-system/jsx';
import { borderBox } from '@pt-frontends/styled-system/recipes';
import { cmsContentPartners } from '@pt-frontends/styled-system-web/recipes';

import type { Cms_Partner } from '@lib/services/cms';

import { Button } from '@ui/button';
import { CmsEditorText } from '@ui/cmsEditorText';
import { CmsLink } from '@ui/cmsLink';
import { Img } from '@ui/img';
import { TagList } from '@ui/tag';
import { Text } from '@ui/text';

// const item = css({
//   p: 6,
//   display: 'flex',
//   flexDirection: 'column',
//   minH: '498px',
//   h: 'full',
//   transition: 'colors'
// });

// const tagList = css({
//   mb: 6
// });

// const logo = css({
//   bg: 'primary.a10',
//   rounded: 4,
//   overflow: 'hidden'
// });

// const logoInner = css({
//   m: 10,
//   pos: 'relative',
//   w: 'calc(100% - 80px) !important',
//   h: 'calc(100% - 80px) !important'
// });

// const img = css({
//   objectFit: 'contain !important',
//   objectPosition: 'center',
//   width: '100%',
//   height: '100%',
//   inset: 0
// });

// const itemTitle = css({
//   mt: 8
// });

// const itemText = css({
//   mt: 2,
//   flexGrow: 1
// });

// const itemLink = css({
//   mt: 6,
//   flexGrow: 1,
//   display: 'flex',
//   alignItems: 'flex-end',
//   justifyContent: 'flex-end'
// });

interface Props {
  data: Cms_Partner;
}

const PartnerItem: React.FC<Props> = ({ data }) => {
  const { item, tagList, logo, logoInner, img, itemTitle, itemText, itemLink } =
    cmsContentPartners();

  const content = (
    <div className={cx('group', borderBox(), item)}>
      <TagList
        className={tagList}
        items={
          data.tags?.sort((a, b) => a.localeCompare(b)).map(tag => ({ key: tag, label: tag })) || []
        }
      />
      {data.logo && (
        <AspectRatio ratio={1.6} className={logo}>
          <div className={logoInner}>
            <Img
              fill
              data={data.logo}
              className={img}
              objectFit="contain"
              objectPosition="center"
            />
          </div>
        </AspectRatio>
      )}
      <Text variant="h5" as="h4" className={itemTitle}>
        {data.title}
      </Text>
      <CmsEditorText variant="blockXs" data={data.text} className={itemText} />
      {data.link && (
        <div className={itemLink}>
          {data.link && data.link._isValid && (
            <Button className="link-btn" variant="tertiary" icon="arrow-right">
              {data.link.title || 'Learn more' /* t('cmsContents.link.learnMore') */}
            </Button>
          )}
        </div>
      )}
    </div>
  );

  if (data?.link?._isValid) {
    return (
      <CmsLink link={data?.link} className="partneritem-link-container">
        {content}
      </CmsLink>
    );
  }

  return content;
};

export default PartnerItem;
