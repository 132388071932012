'use client';

import React from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { filterList } from '@pt-frontends/styled-system/recipes';
import * as Toolbar from '@radix-ui/react-toolbar';
import { ToggleGroupItemProps } from '@radix-ui/react-toolbar';

interface FilterListItemProps extends ToggleGroupItemProps {
  label?: string | React.ReactNode;
  className?: string;
}

const FilterListItem = React.forwardRef<HTMLButtonElement, FilterListItemProps>((props, ref) => {
  const { className, label, children, ...restProps } = props;
  const { toggleItem } = filterList();

  return (
    <Toolbar.ToggleItem
      ref={ref}
      type="button"
      className={cx(toggleItem, className)}
      {...restProps}
    >
      {children || label}
    </Toolbar.ToggleItem>
  );
});
FilterListItem.displayName = 'FilterListItem';

interface FilterListProps extends Omit<Toolbar.ToolbarToggleGroupMultipleProps, 'type'> {
  filters: FilterListItemProps[];
  className?: string;
  allLabel?: string;
  groupCl?: string;
}

const FilterList = React.forwardRef<HTMLDivElement, FilterListProps>((props, ref) => {
  const { allLabel, filters, className, value, onValueChange, groupCl, ...rest } = props;
  const { root, toggleGroup } = filterList();

  const handleChange = (_values: string[]) => {
    let isAll = false;
    if (_values.includes('*')) {
      isAll = true;
    }
    let values = _values.filter(v => v !== '*');

    if (isAll) {
      values = [];
    }

    if (onValueChange) {
      onValueChange(values);
    }
  };

  if (!filters || !filters.length) return null;

  return (
    <Toolbar.Root className={cx(root, className)} ref={ref}>
      <Toolbar.ToggleGroup
        value={value}
        className={cx(toggleGroup, groupCl)}
        onValueChange={handleChange}
        type="multiple"
        {...rest}
      >
        <FilterListItem className="filter-all-btn" value="*">
          {allLabel || 'All'}
        </FilterListItem>
        {filters.map(flt => (
          <FilterListItem {...flt} key={flt.value} />
        ))}
      </Toolbar.ToggleGroup>
    </Toolbar.Root>
  );
});
FilterList.displayName = 'FilterList';

export { FilterList, FilterListItem };
export type { FilterListProps, FilterListItemProps };
