/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import React, { useEffect, useMemo, useState } from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { Center } from '@pt-frontends/styled-system/jsx';
import { srOnly } from '@pt-frontends/styled-system/patterns';
import { cmsContentVideo } from '@pt-frontends/styled-system-web/recipes';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';

import type { Cms_ContentVideo } from '@lib/services/cms';
import { useTranslation } from '@lib/services/i18n/client';

import { Icon } from '@ui/icon';
import { Img } from '@ui/img';

const ReactPlayer = dynamic(() => import('react-player/lazy'));

interface Props {
  data: Cms_ContentVideo;
}

const Video: React.FC<Props> = ({ data }) => {
  const [shouldPlayVideo, setPlayVideo] = useState(false);
  const [isPlayerMounted, setPlayerMounted] = useState(false);
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 1
  });
  const { box, playerBox, video, playIconBox, playBtnLabel, playBtnIcon, videoOverlay } =
    cmsContentVideo({ isPlayerMounted });

  const ratio = useMemo(() => {
    if (data.video.ratio && data.video.ratio !== 0) {
      return (1 / data.video.ratio) * 100;
    }

    return (9 / 16) * 100;
  }, [data.video.ratio]);

  const playBtn = (
    <div className={cx(playIconBox, 'group')}>
      {data.playButtonLabel && <div className={playBtnLabel}>{data.playButtonLabel}</div>}
      <Icon i="play" className={playBtnIcon} />
    </div>
  );

  useEffect(() => {
    if (inView) {
      setPlayerMounted(true);
    }
  }, [inView]);

  useEffect(() => {
    if (data.video.autoplay) {
      setPlayVideo(true);
    }
  }, [data.video.autoplay]);

  return (
    <div ref={ref} style={{ paddingTop: `${ratio}%` }} className={box}>
      <div className={playerBox}>
        <div className={cx(video, videoOverlay)}>
          <Img fill data={data.previewImage} />
          <Center pos="absolute" inset={0} zIndex={1}>
            {playBtn}
          </Center>
        </div>

        {isPlayerMounted && (
          <ReactPlayer
            className={video}
            url={data.video.url}
            width="100%"
            height="100%"
            loop={data.video.loop}
            volume={0.4}
            muted={data.video.autoplay && !data.video.controls}
            pip
            playing={shouldPlayVideo}
            controls={data.video.controls}
            onClickPreview={() => setPlayVideo(true)}
            light={
              data.previewImage ? (
                <div className={video}>
                  <Img fill data={data.previewImage} />
                </div>
              ) : (
                true
              )
            }
            playIcon={
              <div className={cx(playIconBox, 'group')}>
                {data.playButtonLabel && <div className={playBtnLabel}>{data.playButtonLabel}</div>}
                <Icon i="play" className={playBtnIcon} />
                <div className={srOnly()}>{t('uiWeb.cmsContents.video.playVideo')}</div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default Video;
