'use client';

import React from 'react';

import { useTranslation } from '@lib/services/i18n/client';

import { Button } from '@ui/button';

const AuthSignUpAction: React.FC = () => {
  const { t } = useTranslation();

  const handleSignUp = () => {
    // eslint-disable-next-line no-alert
    alert('Sign Up!');
  };

  return (
    <Button size="small" variant="primary" icon="arrow-right" onClick={handleSignUp}>
      {t('uiWeb.auth.signUp')}
    </Button>
  );
};

export { AuthSignUpAction };
