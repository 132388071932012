import { objectToBase64 } from '@lib/utils';

const getImgUrlFromKey = (key: string, transforms: any = {}, format: 'jpg' | 'webp' = 'webp') => {
  const q = objectToBase64(transforms);

  return `/img/${encodeURIComponent(key).replaceAll('.', '%2E')}/${q}.${format}`;
};

const getKeyFormS3Url = (url: string) => {
  const parts = (url.split('//')[1] as string)?.split('/');
  parts.shift();

  let bucket = process.env.CMS_S3_BUCKET;

  return parts.filter(p => p !== bucket).join('/');
};

const getImgUrlFromS3Url = (url: string, transforms: any = {}, format: 'jpg' | 'webp' = 'webp') => {
  const q = objectToBase64(transforms);

  const key = getKeyFormS3Url(url);

  return `/img/${encodeURIComponent(key).replaceAll('.', '%2E')}/${q}.${format}`;
};

export const imageService = {
  getImgUrlFromKey,
  getKeyFormS3Url,
  getImgUrlFromS3Url
};
