import { stringify } from 'qs';

import { getApiError } from '../../../error';
import { CMS_AUTH_HEADER } from '../../configs';
import { Cms_ListResponse, Cms_QueryParams } from '../../types';

import type { Cms_TeamMember } from './teamMember.types';

const BASE_PATH = 'team-members';

/**
 * GET /api/team-members
 */
export const getTeamMembers = async (
  tenantId?: string // Tenant_Uid
): Promise<Cms_ListResponse<Cms_TeamMember>> => {
  const errorMsg = `Failed to fetch team members`;
  try {
    const params: Cms_QueryParams = {
      publicationState: 'live',
      populate: ['image'],
      pagination: {
        page: 1,
        pageSize: 100
      },
      sort: 'lastName:asc',
      filters: tenantId
        ? {
            tenants: {
              uid: tenantId
            }
          }
        : undefined
    };

    const query = stringify(params, { encodeValuesOnly: true });

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}?${query}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'team-members'] }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};
