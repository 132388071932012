import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentQuoteDefaultVariants = {}
const cmsContentQuoteCompoundVariants = []

const cmsContentQuoteSlotNames = [
  [
    "text",
    "cms-quote__text"
  ]
]
const cmsContentQuoteSlotFns = /* @__PURE__ */ cmsContentQuoteSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentQuoteDefaultVariants, getSlotCompoundVariant(cmsContentQuoteCompoundVariants, slotName))])

const cmsContentQuoteFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentQuoteSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentQuoteVariantKeys = [
  "primary"
]

export const cmsContentQuote = /* @__PURE__ */ Object.assign(cmsContentQuoteFn, {
  __recipe__: false,
  __name__: 'cmsContentQuote',
  raw: (props) => props,
  variantKeys: cmsContentQuoteVariantKeys,
  variantMap: {
  "primary": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentQuoteVariantKeys)
  },
})