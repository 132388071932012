import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentBlogPostsDefaultVariants = {}
const cmsContentBlogPostsCompoundVariants = []

const cmsContentBlogPostsSlotNames = [
  [
    "item",
    "cms-blog-posts__item"
  ],
  [
    "button",
    "cms-blog-posts__button"
  ],
  [
    "tagList",
    "cms-blog-posts__tagList"
  ],
  [
    "img",
    "cms-blog-posts__img"
  ],
  [
    "itemLink",
    "cms-blog-posts__itemLink"
  ],
  [
    "itemTitle",
    "cms-blog-posts__itemTitle"
  ]
]
const cmsContentBlogPostsSlotFns = /* @__PURE__ */ cmsContentBlogPostsSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentBlogPostsDefaultVariants, getSlotCompoundVariant(cmsContentBlogPostsCompoundVariants, slotName))])

const cmsContentBlogPostsFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentBlogPostsSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentBlogPostsVariantKeys = []

export const cmsContentBlogPosts = /* @__PURE__ */ Object.assign(cmsContentBlogPostsFn, {
  __recipe__: false,
  __name__: 'cmsContentBlogPosts',
  raw: (props) => props,
  variantKeys: cmsContentBlogPostsVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentBlogPostsVariantKeys)
  },
})